import { useState, useEffect, useRef } from 'react';

import { Typography } from '@mui/material';

import { KeyboardArrowRight } from '@mui/icons-material';

import Link from '../../../src/Link';
import Dropdown from './Dropdown';

const MenuItems = ({ items, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);
    const [pullLeft, setPullLeft] = useState(false);

    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        if (window.innerWidth < ref.current.getBoundingClientRect().right + 245) {
            setPullLeft(true);
        } else {
            setPullLeft(false);
        }
        setDropdown(true);
    };

    const onMouseLeave = () => {
        setDropdown(false);
    };

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    return (
        <div className='menu-items' ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onMouseEnter}>
            {items.href && items.children?.length > 0 ? (
                <>
                    <button
                        type='button'
                        aria-haspopup='menu'
                        // aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        <Link href={items.href}>{items.name}</Link>
                        {depthLevel > 0 && <KeyboardArrowRight />}
                    </button>
                    <Dropdown depthLevel={depthLevel} submenus={items.children} dropdown={dropdown} pullLeft={pullLeft} />
                </>
            ) : items.href ? (
                <Link href={items.href}>{items.name}</Link>
            ) : null}
        </div>
    );
};

export default MenuItems;
