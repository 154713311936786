import React from "react";
import { useRouter } from "next/router";
import { getCookie, setCookie } from "../../utils/cookieHandle";

function useMarketingParams() {
    const router = useRouter();

    React.useEffect(() => {
        const { utm_source, utm_medium, utm_campaign, utm_content, utm_term, gcid } = router.query;
        if (utm_source) setCookie('utm_source', utm_source);
        if (utm_medium) setCookie('utm_medium', utm_medium);
        if (utm_campaign) setCookie('utm_campaign', utm_campaign);
        if (utm_content) setCookie('utm_content', utm_content);
        if (utm_term) setCookie('utm_term', utm_term);
        if (gcid) setCookie('gcid', gcid);
    }, [router.query]);

    return null;
}

export function getMarketingParams() {
    return {
        utm_source: getCookie('utm_source'),
        utm_medium: getCookie('utm_medium'),
        utm_campaign: getCookie('utm_campaign'),
        utm_content: getCookie('utm_content'),
        utm_term: getCookie('utm_term'),
        gcid: getCookie('gcid')
    }
}

export default useMarketingParams;
