export const navItems = [
    {
        name: 'Industry Leader',
        href: '/industry-leader',
        children: [
            {
                name: 'Large Financial Firms',
                href: '/industry-leader/large-firms'
            },
            {
                name: 'Universities',
                href: '/industry-leader/universities'
            },
            {
                name: 'Ministries',
                href: '/industry-leader/ministries'
            },
            {
                name: 'Product and Service Providers',
                href: '/industry-leader/products-services'
            },
            {
                name: 'Partners',
                href: '/partners'
            },
            {
                name: 'Prospects and Clients',
                href: '/industry-leader/prospects-clients'
            },
        ]
    },
    {
        name: 'Join Our Community',
        href: '/community',
        children: [
            {
                name: 'Membership',
                href: '/community/membership',
            },
            {
                name: 'Study Groups',
                href: '/community/study-groups',
                children: [
                    {
                        name: 'Find a Study Group',
                        href: '/community/study-groups/find-a-study-group',
                    },
                    {
                        name: "This Month's Study Group Topic",
                        href: '/community/study-groups/current-topic',
                    },
                    {
                        name: 'Virtual Study Groups',
                        href: '/community/study-groups/virtual-study-groups'
                    }
                ]
            },
        ],
    },
    {
        name: 'Events',
        href: '/events',
        children: [
            {
                name: 'Conference',
                href: '/events/conference'
            },
            {
                name: 'Virtual Events',
                href: '/events/virtual-events'
            },
            {
                name: 'Champions Forum',
                href: '/events/champions-forum'
            },
            {
                name: 'Canada Regional Events',
                href: '/events/canada-regional-events'
            },
        ]
    },
    {
        name: 'Find a CKA®',
        href: '/find-a-cka',
        children: [
            {
                name: 'CKA® Directory Search',
                href: '/find-a-cka/cka-directory-search'
            },
            {
                name: 'Choosing your CKA®',
                href: '/find-a-cka/choosing-your-cka'
            },
        ]
    },
    {
        name: 'Get Certified',
        href: '/get-certified',
        children: [
            {
                name: 'Steps to Get Certified: US',
                href: '/get-certified/steps-to-get-certified',
            },
            {
                name: 'Steps to Get Certified: Canada',
                href: '/get-certified/steps-to-get-certified-canada'
            },
            {
                name: 'CKA® Educational Program',
                href: '/get-certified/cka-educational-program'
            },
            {
                name: 'Kingdom Advisors Core Training',
                href: '/get-certified/core-training'
            }
        ]
    }
];
