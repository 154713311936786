import {
  useContext,
  useEffect,
  useState
} from 'react';

import Head from 'next/head';

import { RequestNextApi } from '../api/Request';
import { ProgressContext } from '../components/Context/progressContext';
import MarketingNavigation from '../navigation/marketing';
import { showArticleBlock } from '../utils/static-pages/function';
import { PAGES_SLUG } from '../utils/static-pages/model';
import { ShowNotification } from '../utils/notification/snackbarUtils';

export default function MarketingHome() {
  const [articleBlocks, setArticleBlocks] = useState();
  const { state: progressContext, update: updateProgress } = useContext(ProgressContext);

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const res = await RequestNextApi.post('/pages',
          {
            slug: PAGES_SLUG.MARKETING_HOME,
          }
        );
        if (res.data.success) {
          setArticleBlocks(res.data.page.articleBlocks);
        } else {
          ShowNotification.error('Fetch page failed');
        }
      } catch (error) {
        ShowNotification.error('Fetch page failed');
      }
    };

    const initPage = async () => {
      updateProgress({ isLoading: true });
      await fetchPage();
      updateProgress({ isLoading: false });
    }

    initPage();
  }, []);




  return (
    <div>
      <Head>
        <title>Kingdom Advisors</title>
        <meta name="description" content="Kingdom Advisors vision is to transform the world by leading the Christian financial industry delivering biblical financial advice." />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <MarketingNavigation>
        <div className='marketing-home'>
          {articleBlocks?.map((item, index) => (
            showArticleBlock(item, index)
          ))}
        </div>
      </MarketingNavigation>
    </div>
  )
}

MarketingHome.isPublic = true;
MarketingHome.getLayout = page => <>{page}</>;
