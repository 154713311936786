import {
    Fragment,
    useState
} from 'react';
import { useRouter } from 'next/router';

import {
    Dialog,
    Transition
} from '@headlessui/react';

import {
    IconButton,
    Tooltip
} from '@mui/material';

import {
    Close,
    MenuOutlined,
} from '@mui/icons-material'

import SideNav from '../../components/SideNav/SideNav';
import Navbar from '../../components/TopNav/Marketing/Navbar';
import UserAccountMenu from '../../components/TopNav/UserAccountMenu';
import { navItems } from './navItems';
import useMarketingParams from '../../components/hooks/useMarketingParams';

export default function MarketingNavigation({ children, isAdmin }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const router = useRouter();

    useMarketingParams()

    const onClickLogo = () => {
        router.push('/');
    };

    return (
        <div className='marketing-nav'>
            {/* Mobile Menu */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative lg:hidden" onClose={setSidebarOpen} style={{ zIndex: '1400' }}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex-1 flex flex-col w-full px-3 pt-3 pb-4 bg-white">
                                <div className="flex justify-end mr-4">
                                    <IconButton
                                        onClick={() => setSidebarOpen(false)}
                                        size="large"
                                        className="mx-1"
                                    >
                                        <Close />
                                    </IconButton>
                                </div>
                                <div className="flex-shrink-0 flex items-center px-4 mt-2">
                                    <img
                                        src="/static/images/logo.png"
                                        alt="Kingdom Advisor Logo"
                                        width={250}
                                        className='h-auto'
                                        onClick={onClickLogo}
                                    />
                                </div>
                                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                    <SideNav navItems={navItems} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="flex flex-col flex-1 justify-between h-screen">
                <div className="sticky top-0 flex-shrink-0 flex h-16 lg:h-fit bg-sta-400 shadow" style={{ zIndex: '1300' }}>
                    <div className="flex-1 px-1 flex justify-between bg-white">
                        <div className={"flex flex-row justify-start items-end w-full"}>
                            <div className="hidden lg:flex items-center flex-shrink-0 px-4 h-full">
                                <img
                                    src="/static/images/logo.png"
                                    alt="Kingdom Advisor Logo"
                                    width={210}
                                    height={'auto'}
                                    onClick={onClickLogo}
                                />
                            </div>
                            <div className='bg-white mx-1 flex lg:hidden items-center h-full'>
                                <Tooltip title='Menu'>
                                    <IconButton
                                        onClick={() => setSidebarOpen(true)}
                                        size="large"
                                    >
                                        <MenuOutlined />
                                    </IconButton>
                                </Tooltip>
                                <img
                                    src="/static/images/logo.png"
                                    alt="Kingdom Advisor Logo"
                                    height={'auto'}
                                    onClick={onClickLogo}
                                    className="lg:hidden w-3/4 sm:w-64"
                                />
                            </div>
                            <div className="hidden lg:flex items-center px-4 h-full py-2">
                                <Navbar isAdmin={isAdmin} />
                            </div>
                        </div>
                        <div className="flex items-center">
                            <UserAccountMenu type='public' />
                        </div>
                    </div>
                </div>
                <main className="flex-1">
                    {children}
                </main>
            </div>
        </div>
    )
}
