import MenuItems from './MenuItems';

const Dropdown = ({ submenus, dropdown, depthLevel, pullLeft }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 
        ? (pullLeft ? 'dropdown-submenu pull-left' : 'dropdown-submenu pull-right')
        : '';

    return (
        <div
            className={`dropdown ${dropdownClass} ${
                dropdown ? 'show' : ''
            }`}
        >
            {submenus.map((submenu, index) => (
                <MenuItems
                    items={submenu}
                    key={index}
                    depthLevel={depthLevel}
                />
            ))}
        </div>
    );
};

export default Dropdown;
