import { useMemo } from 'react';
import { navItems } from '../../../navigation/marketing/navItems';
import { staffNavigation } from '../../../navigation/navigation';
import MenuItems from './MenuItems';

const Navbar = ({ isAdmin = false }) => {
    const navs = useMemo(() => (isAdmin ? staffNavigation : navItems), [navItems, staffNavigation, isAdmin]);
    return (
        <div className='flex'>
            <div className='menus'>
                {navs.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
                })}
            </div>
        </div>
    );
};

export default Navbar;
